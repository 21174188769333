<template>
  <div class="root_cont image blue_bg">
    <div class="blue_bg_gradiant">
      <div class="container">
        <div class="head_text">
          <h3 class="fw-800">{{ t.h_1 }}</h3>
          <h4 class="mt-4 fw-200">
            {{ t.h_2 }}
          </h4>
          <h5 class="mt-4">
            {{ t.h_3 }}
          </h5>
          <h5 class="mt-2">
            {{ t.h_4 }}
          </h5>
          <div class="mt-4">
            <img width="140" src="/static/images/svg/HrvatskaLocks.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "THE PROBLEM",
        "h_2": "For historical reasons, approximately 10% of all Croatian land lies economically dormant, due to issues with establishing legal title.",
        "h_3": "Without legal title, land cannot be developed or sold legally.",
        "h_4": "The estimated cost to the owners of so-called “locked” land, and by extension the Croatian economy, runs into tens of billions of Euros.",
      },
      "hr": {
        "h_1": "PROBLEM",
        "h_2": "Zbog povijesnih razloga, približno 10% zemljišta cijele Hrvatske je gospodarski neiskorišteno upravo zbog problema s uspostavom čistog vlasništva.",
        "h_3": "Bez čistog vlasništva, zemljište se ne može legalno razvijati niti prodavati.",
        "h_4": "Procijenjeni trošak vlasnika takozvanog “zaključanog” zemljišta, a samim tim i hrvatskog gospodarstva, doseže desetke milijardi eura.",
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithSecond",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.image {
  background-position-x: center;
  background-position-y: 60%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_locksmith/Problem.jpg"); /* The image used */
  z-index: 1;
}

.head_text {
  padding: 50px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

h5 {
  line-height: 1.4;
  font-weight: 400 !important;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }

}
</style>
