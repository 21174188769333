<template>
  <div class="root_cont">
    <div class="t_cont">
      <div class="blue_bg_gradiant">
        <div class="container">
          <div class="head_text">
            <h3 class="fw-800">{{ t.h_1 }}</h3>
            <h5 class="mt-4 fw-400">
              {{ t.h_2 }}
            </h5>
            <div class="row mt-4 text-left">
              <div class="col-12 col-md-6">
                <p class="point"><span class="mark_ta">✓</span><span v-html="t.l1"></span></p>
                <p class="point"><span class="mark_ta">✓</span><span v-html="t.l2"></span></p>
                <p class="point"><span class="mark_ta">✓</span><span v-html="t.l3"></span></p>
              </div>
              <div class="col-12 col-md-6">
                <p class="point"><span class="mark_ta">✓</span><span v-html="t.l4"></span></p>
                <p class="point"><span class="mark_ta">✓</span><span v-html="t.l5"></span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "THE CHALLENGE",
        "h_2": "Unlocking the value in Locked land has proved insurmountable for most people\n" +
            "due to the significant complexity, and therefore cost, involved including:",
        "l1": "Resolving inconsistencies across different land registries.",
        "l2": "Agreeing allocation between multiple co-owners of land.",
        "l3": "Tracing owners, many of whom have left the country.",
        "l4": "Tracing the relatives of deceased owners.",
        "l5": "The complexity and cost of navigating the legal system to establish clear title.",
      },
      "hr": {
        "h_1": "IZAZOV",
        "h_2": "Otključavanje vrijednosti zaključanog zemljišta pokazalo se nepremostivim za većinu ljudi zbog značajne složenosti, a time i iznimno velikih troškova, uključujući:",
        "l1": "Rješavanje nedosljednosti u različitim zemljišnim knjigama.",
        "l2": "Dogovora oko raspodjele između više suvlasnika zemljišta.",
        "l3": "Traženje vlasnika, od kojih su mnogi napustili zemlju.",
        "l4": "Traženje rođaka preminulih vlasnika.",
        "l5": "Složenost i troškovi snalaženja u pravnom sustavu radi uspostavljanja čistog vlasništva.",
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithThird",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.t_cont {
  width: 100%;
  text-align: center;
  color: #fffff3;
  position: relative;
  z-index: 3;
}

.head_text {
  padding: 50px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

p.point {
  position: relative;
  padding-left: 10px;
}

p.point .mark_ta {
  position: absolute;
  left: -10px;
  top: -3px;
  font-size: 20px;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }

  .t_cont {
    padding-top: 0;
  }

  .blue_bg {
    background-color: rgba(4, 43, 93, 1);
  }
}

@media (max-width: 576px) {
  .t_cont {
    padding-top: 0;
  }

  .blue_bg {
    background-color: rgba(4, 43, 93, 1);
  }

  .img_cont {
    position: relative;
    height: 250px;
  }
}
</style>
