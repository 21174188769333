<template>
  <div class="root_cont image blue_bg">
    <div class="t_cont">
      <div class="blue_tras_bg">
        <div class="container">
          <div class="head_text">
            <h2 class="fw-800">{{ t.h_1 }}</h2>
            <h3 class="mt-3 fw-200">
              {{ t.h_2 }}
            </h3>
            <h5 class="mt-3">
              {{ t.h_3 }}
            </h5>
            <div class="mt-3">
              <img width="250" src="/static/images/web_locksmith/TriIkone.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "INTRODUCING LOCKSMITH",
        "h_2": "Identify, Validate and Unlock Value from your Ancestral Right to Land at Zero Upfront Cost...",
        "h_3": "...and help Croatia to return billions of square meters of economically dormant real-estate to productivity"
      },
      "hr": {
        "h_1": "PREDSTAVLJAMO LOCKSMITH",
        "h_2": "Identificirajte, potvrdite i otključajte vrijednost prava na zemljište Vaših predaka bez ikakvih troškova unaprijed...",
        "h_3": "...i pomozite Hrvatskoj vratiti milijarde kvadratnih metara gospodarski neiskorištenih nekretnina u produktivnost"
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithFirst",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.image {
  background-position-x: center;
  background-position-y: 20%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_locksmith/Naslovna.jpg"); /* The image used */
  z-index: 1;
}

.t_cont {
  padding-top: 30vh;
  width: 100%;
  position: relative;
  z-index: 3;
}

.head_text {
  padding: 40px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

.blue_tras_bg {
  background-color: rgba(4, 43, 93, 0.8);
}

h3 {
  line-height: 1.4;
}

h5 {
  line-height: 1.4;
  font-weight: 400 !important;
}

@media (max-width: 576px) {

}
</style>
