<template>
  <div class="Carousel">
    <div class="root_cont">
      <div ref="carousel_cont" class="carousel_cont" :style="{overflow: overflow ? 'visible' : null}">
        <div ref="carousel_elements" class="carousel_elements">
          <slot></slot>
        </div>
        <div v-if="left_arrow_visible" class="arrow_cont left" @click="left()"><div class="arrow left"><Arrow /></div></div>
        <div v-if="right_arrow_visible" class="arrow_cont right" @click="right()"><div class="arrow right"><Arrow /></div></div>
      </div>
      <div class="dots" v-if="min_x_pos">
        <div
            v-for="n in Math.floor(-(min_x_pos+.01)/slide_amount)+2"
            class="dot"
            :class="{active: active_dot === n}"
            @click="() => slideTo(-slide_amount*(n-1))"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {TweenMax} from "gsap/all";
import Swipe from "@/components/Elements/Swipe"

export default {
  name: "Carousel",
  components: {Arrow},
  props: ['animate', 'overflow'],
  data() {
    return {
      width: null,
      cont_width: null,
      slide_amount: null,
      x_pos: 0,
      min_x_pos: null,

      active_dot: 1
    };
  },
  mounted() {this.calculatePositions(); this.setupSwipeHandler(); if (this.animate) this.animateElements();},
  updated() {this.calculatePositions();},
  methods: {
    calculatePositions() {
      const vm = this;
      vm.width = vm.$refs.carousel_elements.scrollWidth
      vm.cont_width = vm.$refs.carousel_cont.getBoundingClientRect().width;
      const item_width = vm.$refs.carousel_cont.querySelector('.carousel_item_cont').getBoundingClientRect().width;
      vm.slide_amount = item_width*2 < window.innerWidth ? item_width*2 : window.innerWidth
      vm.min_x_pos = vm.cont_width - vm.width;
    },
    left() {
      this.slideTo(this.x_pos + this.slide_amount)
    },
    right() {
      this.slideTo(this.x_pos - this.slide_amount)
    },
    slideTo(x_pos) {
      const vm = this;
      if (x_pos > 0) x_pos = 0
      if (x_pos < vm.min_x_pos) x_pos = vm.min_x_pos
      vm.x_pos = x_pos;
      vm.active_dot = Math.ceil(-vm.x_pos / vm.slide_amount)+1;
      TweenMax.to(vm.$refs.carousel_elements, 1, { x: vm.x_pos });
    },
    setupSwipeHandler() {
      const vm = this,
          swipe = new Swipe(
              vm.$refs.carousel_elements,
              vm.right,
              vm.left,
              () => true,
              () => true,
          )
    },
    animateElements() {
      const elements = this.$el.querySelector('.carousel_elements').parentNode.querySelectorAll(".carousel_elements > div")
      TweenMax.set(elements, { x:  0, alpha: 1});
      TweenMax.staggerFrom(elements, 1, { x:  -100, alpha: 0}, .2);
    }
  },
  computed: {
    right_arrow_visible() {return this.x_pos > this.min_x_pos},
    left_arrow_visible() {return this.x_pos < 0},
  }
};

function Arrow() {
  return (
    <svg viewBox="0 0 100 100"><path fill="currentColor" d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" /></svg>
  )
}

</script>

<style lang="scss">
.Carousel {

  .carousel_cont {
    position: relative;
    overflow: hidden;
  }

  .carousel_elements {
    width: max-content;
    position: relative;
    display: flex;
  }

  .carousel_item_cont {
    flex-shrink: 0;
  }

  .carousel_item {
    margin: 0 12px;
  }

  @media (max-width: 576px) {
    .carousel_item_cont {
      width: 100vw;
      padding: 0 20px;
    }

    .carousel_item {
      width: initial!important;
    }
  }

  .arrow_cont {
    background-color: rgb(4,43,93);
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 40%;
    cursor: pointer;
    color: #fff;

    opacity: 0.5;
    transition: opacity 0.3s;
  }

  .arrow_cont.left {
    left: 0;
  }

  .arrow_cont.right {
    right: 0;
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: max-content;
  }

  .arrow_cont:hover {
    opacity: 1;
  }

  .arrow svg {
    position: relative;
    width: 70%;
    margin: 0 15%;
  }

  .arrow.left svg {
    transform: rotate(0deg);
  }

  .arrow.right svg {
    transform: rotate(180deg);
  }

  .dots {
    text-align: center;
    margin-top: 40px;

    .dot {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 10px;
      display: inline-block;
      border: 5px solid #fff;
      transition: .3s;
      background-color: #fff;

      &.active {
        background-color: rgba(0,0,0,0);
      }
    }
  }
}
</style>
