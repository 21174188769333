import {TimelineLite, TweenLite, TweenMax} from "gsap/all"

export default function Swipe(el, toLeft, toRight, hasNext, hasPrev) {
  this.el = el;
  this.toLeft = toLeft;
  this.toRight = toRight;
  this.hasNext = hasNext;
  this.hasPrev = hasPrev;

  this.setup()
}

Swipe.prototype.setup = function () {
  let self = this,
    el = this.el;

  {
    let mouse_body, mouse_offset, active, x;

    function mousePos(e, el) {
      const rect = el.getBoundingClientRect();  // rel to
      return [e.touches[0].clientX - rect.left, e.touches[0].clientY - rect.top]
    }
    const mouseBody = (e) => mousePos(e, document.querySelector("body"))

    function onMouseDown(e) {
      x = self.el.getBoundingClientRect().x
      mouse_body = mouseBody(e)
      active = true
    }

    function onMouseMove(e) {
      if (!active) return

      const m = mouseBody(e)
      mouse_offset = [m[0] - mouse_body[0], m[1] - mouse_body[1]];

      if (Math.abs(mouse_offset[1]) > Math.abs(mouse_offset[0])) {
        active = false
        onMouseUp();
        return
      }
      e.preventDefault()
      TweenMax.set(el, {x: mouse_offset[0]+x})
    }

    function onMouseUp() {
      active = false;
      const toLeft = (mouse_offset[0] < 0);

      if (
        Math.abs(mouse_offset[0]) < window.innerWidth*.25
        || (toLeft ? !self.hasNext() : !self.hasPrev())
      ) TweenLite.to(el, .5, {x: x})
      else {
       toLeft ? self.toLeft() : self.toRight()
      }
    }

    el.addEventListener("touchstart", onMouseDown, false)
    el.addEventListener("touchmove", onMouseMove, false)
    el.addEventListener("touchcancel", onMouseUp, false)
    el.addEventListener("touchend", onMouseUp, false)
  }
}
