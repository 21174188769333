<template>
  <div class="WebLocksmithFifth root_cont blue_bg pb-4">
    <div class="container position-relative">
      <div class="head_text">
        <h3 class="fw-800">{{ t.h_1 }}</h3>
      </div>
      <Carousel :overflow="true">
        <template v-for="n in 6">
          <div class="carousel_item_cont d-inline-block">
            <div class="carousel_item">
              <Card :text="[t[`c${n}_1`], t[`c${n}_2`], t[`c${n}_3`]]" :n="n" :$lang="$lang" />
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Elements/Carousel"
const t =
    //<i18n>
    {
      "en": {
        "h_1": "HOW IT WORKS",

        "c1_1": "Enter into a Locksmith Agreement",
        "c1_2": "The Seller commits to selling the\n" +
            "land once legal title has been\n" +
            "established.",
        "c1_3": "TA aggregates multiple claims\n" +
            "and secures the funding from a\n" +
            "3rd party to fund the process",

        "c2_1": "Cleaning Process\n" +
            "Commences",
        "c2_2": "TA manages the highly complex\n" +
            "process of establishing clean title\n" +
            "– a process that will take several\n" +
            "years.",

        "c3_1": "The Value of Land\n" +
            "Increases",
        "c3_2": "The value of your land will rise over\n" +
            "time as the process of establishing\n" +
            "clean title progresses.",
        "c3_3": "Where possible, we will maximise\n" +
            "the value ahead of its sale in\n" +
            "a number of ways including\n" +
            "consolidation with surrounding\n" +
            "plots.",

        "c4_1": "Land is Sold",
        "c4_2": "We act at all times on behalf of you,\n" +
            "as the seller of land.",
        "c4_3": "Our entire process ensures full\n" +
            "alignment between all parties, at\n" +
            "all times, in achieving the highest\n" +
            "possible price for the land at sale.",

        "c5_1": "Proceeds Distributed",
        "c5_2": "Following a sale, the investor\n" +
            "recoups their outlay, plus a small\n" +
            "return. The net proceeds are then\n" +
            "split 90% to you and 10% to Terra\n" +
            "Adriatica as a commission for\n" +
            "facilitating the service.",

        "c6_1": "Everyone Benefits",
        "c6_2": "The land is freed up for legitimate\n" +
            "development and use to the\n" +
            "benefit of local communities and\n" +
            "the Croatian economy as a whole.",
      },
      "hr": {
        "h_1": "KAKO FUNKCIONIRA",

        "c1_1": "Sklopite Locksmith Ugovor",
        "c1_2": "Prodavatelj se obvezuje prodati zemlju nakon što se uspostavi čisto vlasništvo.",
        "c1_3": "TA objedinjuje više zahtjeva i osigurava sredstva od treće strane za financiranje procesa.",

        "c2_1": "Započinje postupak čišćenja",
        "c2_2": "TA upravlja vrlo složenim postupkom uspostave čistog vlasništva – proces koji će trajati nekoliko godina.",

        "c3_1": "Vrijednost zemljišta raste",
        "c3_2": "Vrijednost Vaše zemlje s vremenom će rasti kako proces uspostave čistog vlasništva odmiče.",
        "c3_3": "Gdje je moguće, maksimizirat ćemo vrijednost prije prodaje na više načina, uključujući konsolidaciju s okolnim parcelama.",

        "c4_1": "Prodaja zemljišta",
        "c4_2": "U svakom trenutku djelujemo u interesu Vas, prodavatelja zemljišta.",
        "c4_3": "Čitav naš postupak osigurava potpuno usklađivanje svih strana, u svakom trenutku, u postizanju najveće moguće cijene zemljišta prilikom prodaje.",

        "c5_1": "Raspodjela prihoda",
        "c5_2": "Nakon prodaje, investitor nadoknađuje svoje troškove, plus mali povrat. Zatim se neto prihodi dijele: Vama pripada 90% i Terra Adriatici 10%, kao naknada za omogućavanje usluge.",

        "c6_1": "Svi dobivaju",
        "c6_2": "Zemljište je oslobođeno za legitiman razvoj i upotrebu za dobrobit lokalnih zajednica, ali i hrvatskog gospodarstva u cjelini.",
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithFifth",
  components: {Carousel, Card},
  data() {
    return {
      t: t[this.$lang]
    };
  },
};

function Card({text, n, $lang}) {
  return (
    <div class="card_ta card">
      <div class="text-center mt-3" style="height: 100px">
        <img src={getImage(n, $lang)} style={`height: ${imgH(n)}%; margin-top: ${(100 - imgH(n))/4}%`} />
      </div>
      <div class="fw-800 mb-3 mt-4" style="font-size: 19px">{text[0]}</div>
      <p class="mb-2">{text[1]}</p>
      <p>{text[2]}</p>
    </div>
  )
  function imgH(n) {
    return [null, 85, 110, 105, 70, 90, 85][n]
  }
}

function getImage(n, lang) {
  return "/static/images/svg/" + [null, "1Handshake.svg", "2Hrvatska shema.svg", `3Graf${lang === 'hr' ? 'HR' : ''}.svg`,
    "4Cekic.svg", `5Investor${lang === 'hr' ? 'HR' : ''}.svg`, "6EveryoneBenefits.svg"][n]
}

</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.head_text {
  padding: 50px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

.card_ta {
  background-color: #fff;
  padding: 20px 20px;
  height: 440px;
  width: 270px;
  white-space: normal;
  text-align: left;
  border-radius: 10px;
  font-size: 14px;
  margin: 0 auto;

  color: rgb(4,43,93);
}

.container {
  overflow: visible;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }

  .container {
    margin: 0;
    padding: 0;

  }
}
</style>
