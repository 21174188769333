<template>
  <div class="root_cont image blue_bg">
    <div class="blue_bg_gradiant">
      <div class="container">
        <div class="head_text">
          <h3 class="fw-800">{{ t.h_1 }}</h3>
          <h5 class="mt-4 fw-400">
            {{ t.h_2 }}
          </h5>
          <div class="row mt-4 text-left main_text">
            <div class="col-12 col-md-6">
              <p class="point"><span class="mark_ta">✓</span><span v-html="t.p1"></span></p>
            </div>
            <div class="col-12 col-md-6">
              <p class="point"><span class="mark_ta">✓</span><span v-html="t.p2"></span></p>
            </div>
          </div>
          <div>
            <span class="btn ta_disabled" @click="$store.dispatch('auth/openAuth', 2)">
              <span>{{ t.REGISTER }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "REGISTER TODAY TO:",
        "p1": "Download a copy of your free, fully  personalized report on your family's possible claim to Croatian land at zero obligation.",
        "p2": "Generate value from the sale of any land we are able to validate as yours – at zero upfront cost to you",
        "REGISTER": "REGISTER"
      },
      "hr": {
        "h_1": "REGISTRIRAJTE SE DANAS:",
        "p1": "Preuzmite primjerak svog besplatnog, potpuno personaliziranog izvješća o mogućem pravu Vaše obitelji na hrvatsko zemljište bez ikakvih obveza.",
        "p2": "Generirajte vrijednost od prodaje bilo kojeg zemljišta za koje možemo potvrditi da je Vaše – bez ikakvih troškova unaprijed.",
        "REGISTER": "REGISTRIRAJ SE"
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithSixth",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.image {
  background-position-x: center;
  background-position-y: 60%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_locksmith/Register.jpg"); /* The image used */
  z-index: 1;
}

.head_text {
  padding: 60px 0 70px;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

.main_text {
  line-height: 1.7;
  font-weight: 400 !important;
}


p.point {
  position: relative;
  padding-left: 10px;
}

p.point .mark_ta {
  position: absolute;
  left: -10px;
  top: -3px;
  font-size: 20px;
}

.btn.green {
  background-color: #67c532;
  color: #fff;
  padding: 10px 80px;
  border: #67c532 solid 1.5px;
}

.btn.green:hover {
  color: #67c532!important;
  background-color: transparent;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }
}

</style>
