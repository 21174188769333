<template>
  <div class="root_cont blue_bg pb-3">
    <div class="container" style="position:relative;z-index: 1">
      <div class="head_text">
        <h3 class="fw-800">
          <span class="fw-800">{{ t.h_1 }}</span> <br>
          <span class="fw-400">{{ t.h_2 }}</span>
        </h3>
        <div class="row mt-5 text-left">
          <div class="col-12 col-md-6 d-flex mb-5">
            <div>
              <img width="100" :src="`/static/documents/TheLocksmithExplainedSolutions${$lang === 'hr' ? 'HR' : ''}.jpg`" alt="Locksmith Explained">
            </div>
            <div class="pl-3">
              <h6 class="fw-800">{{ t.t1 }}</h6>
              <p>{{ t.t2 }}</p>
              <a :href="`/${$lang}/locksmith_explained`"
                  target="_blank"
                  class="btn green">{{ t.OPEN }}</a>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex">
            <div>
              <img width="100" :src="`/static/documents/ReportFree-${$lang === 'hr' ? 'HR' : 'ENG'}.png`" alt="ReportFree">
            </div>
            <div class="pl-3">
              <h6 class="fw-800">{{ t.t3 }}</h6>
              <p>{{ t.t4 }}</p>
              <p>{{ t.t5 }}</p>
              <a  :href="`/${$lang}/free_report`"
                  target="_blank"
                  class="btn green">{{ t.OPEN }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid"></div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "NEED MORE INFO?",
        "h_2": "No problem!",
        "t1": "Locksmith Explained",
        "t2": "A comprehensive, plain-language explanation of how the process works, who benefits and and why it may, or may not be right for you.",
        "t3": "A Sample Free Report",
        "t4": "This sample report will show you how, from using the information you provide in your family tree, we are able to provide an initial assessment as to what land your family may lay claim.",
        "t5": "It is the first step in your Journey with TA. If you enter into a Locksmith Agreement, we will then work to refine and assess these results.",
        "OPEN": "OPEN"
      },
      "hr": {
        "h_1": "POTREBNO VAM JE JOŠ INFORMACIJA?",
        "h_2": "Nema problema!",
        "t1": "Objašnjenje Locksmitha",
        "t2": "Sveobuhvatno, jednostavnim jezikom pisano objašnjenje kako postupak funkcionira, kome sve koristi te zašto bi mogao, ili ne, biti pravi izbor za Vas.",
        "t3": "Primjerak Besplatnog izvješća",
        "t4": "Ovaj primjerak izvješća, koristeći podatke iz Vašeg obiteljskog stabla, pokazat će Vam kako možemo dati početnu procjenu na koja zemljišta biste Vi ili Vaša obitelj mogli imati prava.",
        "t5": "To je Vaš prvi korak na putovanju s TA. Ako sklopimo Locksmith ugovor, te rezultate ćemo dodatno pročistiti i procijeniti.",
        "OPEN": "VIDI"
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithSeventh",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.head_text {
  padding: 40px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

p {
  font-size: 12px;
}

a.btn {
  text-decoration: none;
  font-size: 14px;
}

.btn.green {
  background-color: #67c532;
  color: #fff;
  padding: 7px 40px;
  width: 100%;
  border: #67c532 solid 1.5px;
}

.btn.green:hover {
  color: #67c532!important;
  background-color: transparent;
}

.grid {
  position: absolute;
  bottom: 0;
  height: 60%;
  width: 100%;
  opacity: .5;
  transform: rotateY(180deg);

  background-position-x: center;
  background-position-y: 0;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/GridV2.png"); /* The image used */
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }
}

</style>
