<template>
  <div class="root_cont image blue_bg">
    <div class="blue_bg_gradiant">
      <div class="container">
        <div class="head_text">
          <h3 class="fw-800">{{ t.h_1 }}</h3>
          <h5 class="mt-4 fw-400">
            {{ t.h_2 }}
          </h5>
          <div class="mt-4">
            <svg-locksmith-icon width="110px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "THE SOLUTION - LOCKSMITH",
        "h_2": "Locksmith facilitates the release of value from the sale of Locked Land\n" +
            "at zero upfront cost delivering 90% of the net proceeds from sale.",
      },
      "hr": {
        "h_1": "RJEŠENJE – LOCKSMITH",
        "h_2": "Locksmith omogućava oslobađanje vrijednosti od prodaje zaključanog zemljišta bez ikakvih troškova unaprijed, isporučujući 90% neto prodajnih prihoda.",
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithForth",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.image {
  background-position-x: center;
  background-position-y: 30%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_locksmith/Solution.jpg"); /* The image used */
  z-index: 1;
}

.head_text {
  padding: 50px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

h5 {
  line-height: 1.4;
  font-weight: 400 !important;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }
}
</style>
