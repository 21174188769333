<template>
  <div class="root_cont image blue_bg">
    <div class="blue_bg_gradiant">
      <div class="container">
        <div class="head_text">
          <h3 class="fw-800">{{ t.h_1 }}</h3>
          <h5 class="fw-400">
            {{ t.h_2 }}
          </h5>
          <div style="width: 500px; max-width: 100%; font-size: 14px; margin: auto">{{ t.h_3 }}</div>
          <div class="mt-5">
            <img src="/static/images/svg/1Handshake_fff.svg" width="110" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "OUR MANTRA",
        "h_2": "We act at all times on behalf of you, as the seller of land.",
        "h_3": "Our entire process ensures full alignment between all parties, at all times, in achieving the highest possible price for the land at sale.",
      },
      "hr": {
        "h_1": "NAŠA MANTRA",
        "h_2": "U svakom trenutku djelujemo u Vašem interesu.",
        "h_3": "Naš cjelokupni proces osigurava potpunu usklađenost između svih strana, u svakom trenutku, prilikom postizanja najviše prodajne cijene zemljišta.",
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebLocksmithEighth",
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped>
.root_cont {
  text-align: center;
  position: relative;
}

.image {
  background-position-x: center;
  background-position-y: 35%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_locksmith/OurMantra.jpg"); /* The image used */
  z-index: 1;
}

.head_text {
  padding: 50px 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
}

h5 {
  line-height: 1.4;
  font-weight: 400 !important;
}

@media (max-width: 576px) {
  .head_text {
    max-width: 100%;
  }
}

</style>
