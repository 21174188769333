<template>
  <div class="WebLocksmith">
    <WebLocksmithFirst />
    <WebLocksmithSecond />
    <WebLocksmithThird />
    <WebLocksmithForth />
    <WebLocksmithFifth />
    <WebLocksmithSixth />
    <WebLocksmithSeventh />
    <WebLocksmithEighth />
  </div>
</template>

<script>
import WebLocksmithFirst from "@/components/web/locksmith/WebLocksmithFirst"
import WebLocksmithSecond from "@/components/web/locksmith/WebLocksmithSecond"
import WebLocksmithThird from "@/components/web/locksmith/WebLocksmithThird"
import WebLocksmithForth from "@/components/web/locksmith/WebLocksmithForth"
import WebLocksmithFifth from "@/components/web/locksmith/WebLocksmithFifth"
import WebLocksmithSixth from "@/components/web/locksmith/WebLocksmithSixth"
import WebLocksmithSeventh from "@/components/web/locksmith/WebLocksmithSeventh"
import WebLocksmithEighth from "@/components/web/locksmith/WebLocksmithEighth"
export default {
  name: "Locksmith",
  components: {
    WebLocksmithEighth,
    WebLocksmithSeventh,
    WebLocksmithSixth,
    WebLocksmithFifth,
    WebLocksmithForth,
    WebLocksmithThird,
    WebLocksmithSecond,
    WebLocksmithFirst
  }
};
</script>

<style lang="scss">
  .WebLocksmith {
    .blue_bg_gradiant {
      background: linear-gradient(0deg, rgba(4, 43, 93, 0) 10%, rgba(4, 43, 93, .3) 25%, rgba(4, 43, 93, 0.5) 40%, rgba(4, 43, 93, 0.6) 50%, rgba(4, 43, 93, 0.7) 60%, rgba(4, 43, 93, 0.9) 100%)
    }
    .blue_bg_gradiant_from_bottom {
      background: linear-gradient(180deg, rgba(4, 43, 93, 0) 10%, rgba(4, 43, 93, .3) 25%, rgba(4, 43, 93, 0.5) 40%, rgba(4, 43, 93, 0.6) 50%, rgba(4, 43, 93, 0.7) 60%, rgba(4, 43, 93, 0.9) 100%)
    }
  }
</style>
